.breadcrumb {
	padding: 0px 15px;
    margin-bottom: 0px;
    list-style: none;
    background-color: transparent;
    border-radius: 0px;
}
.breadcrumb-item {
	&.active {
		color: $default-dark;
	}
	+ .breadcrumb-item::before {
	content: ">";
	padding-left: 10px;
    padding-right: 10px;
    color: $default-text-color;
	}
}
.back {
	position: absolute;
    right: 30px;
    &:before {
    	content: "<";
    	padding-left: 10px;
    	padding-right: 10px;
        display: inline-block;
        color: $default-text-color;
    }
}