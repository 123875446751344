.fc {
	.fc-day {
		cursor: pointer;
	}
	.fc-daygrid-day.fc-day-today {
		background-color: $menu-header-color!important;
	}
	.fc-button {
		border-radius: 0!important;
		&:hover, &:focus, &:active {
			box-shadow: none!important;
			background-color: $default-logo-dark!important;
		}
	}
	.fc-button-primary {
		background-color: $default-logo!important;
		border-color: $default-logo!important;
	}
	.fc-event {
		background-color: $default-logo!important;
		border-color: $default-logo!important;
		border-radius: 0;
	}
	.fc-day-past .fc-event {
		background-color: $default-warning!important;
		border-color: $default-warning!important;
	}
	.fc-today-button {
		display: none!important;
	}
	.selected {
		.fc-daygrid-day-frame {
			border: 1px solid $default-logo;
		}
	}
}
