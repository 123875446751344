@import 'font-awesome';
@import 'themify-icons';
@import 'exclusive-icons';
@import 'icomoon';
@import 'app';

.text-data-presentation {
	p {
		color: $default-dark;
		margin-bottom: 10px;
	}
}