//==================================
// 	Fonts
//==================================


$font-primary:            Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;      
$font-secondary:          Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;         


//==================================
// 	Default color
//==================================

$default-dark:            #1a3b59;
$default-white:           #ffffff;
$default-logo:            #4d4d4d;
$default-logo-dark:       darken($default-logo, 10%);
$default-gray:            #f6f7fb;
$default-text-color:      #808080;
$default-primary:         $default-dark;
$default-success:         #2ecd99;
$default-success-alt:     #a5dc85;
$default-success-border:  #edf8e8;
$default-info:            #038fe4;
$default-warning:         #f7931b;
$default-danger:          #ec6449;

//==================================
// 	Inverse color
//==================================
$inverse-primary :        lighten($default-primary, 30%);
$inverse-success :        lighten($default-success, 45%); 	   
$inverse-info :           lighten($default-info, 45%);         
$inverse-warning :        lighten($default-warning, 45%);	   
$inverse-danger :         lighten($default-danger, 35%);	   

//==================================
// 	Misc
//==================================
$border-color:            #e6ecf5;
$button-border-color:	  #d4deee;
$border-accent-color:     #d2d2d2;
$background-color: 		  #e9edf0;
$menu-header-color:		  #f0f0f3;
$focus-color: 			  #dedede;
$header-height:           65px;
$offscreen-size:          267px;
$collapsed-size:          70px;
$collapsed-size-inner:	  50px;
$side-nav-dark:           #313644;
$side-nav-dark-font:      #99abb4;
$side-nav-dark-border:    rgba(120,130,140,0.3);
$logo-color: 			  #040066;

//==================================
// 	Header Themes
//==================================
$theme-primary:    $default-primary;
$theme-info:       $default-info;
$theme-success:    desaturate($default-success, 5%);
$theme-danger:     #f53f61;
$theme-dark:       lighten($side-nav-dark, 10%);

//==================================
// 	Social media color
//==================================

$facebook :               #3B579D;
$twitter :                #2CAAE1;
$instagram :              #5d4a3b;
$google-plus :            #dc4a38;
$pinterest :              #c9181f;
$skype :                  #00AAF1;
$linkedin :               #0177b5;
$youtube :                #DE2825;
$yahoo :                  #7b0099;
$rss :                    #f58432;
$dropbox :                #007EE6;
$sound-cloud :            #FF6A22;
$vimeo :                  #1BB6EC;
$bing :                   #ffb900;
$dribbble :               #ea4c89;
$html5 :                  #f16528;
$sharethis :              #50b948;
$tumblr :                 #36465d;
$wordpress :              #0087be;

:export {
  	fontPrimary: $font-primary;   
	fontSecondary: $font-secondary;       
	defaultDark: $default-dark;
	defaultWhite: $default-white;
	defaultLogo: $default-logo;
	defaultLogoDark: $default-logo-dark;
	defaultGray: $default-gray;
	defaultTextPrimary: $default-text-color;
	defaultPrimary: $default-primary;
	defaultSuccess: $default-success;
	defaultSuccessAlt: $default-success-alt;
	defaultSuccessBorder: $default-success-border;
	defaultInfo: $default-info;
	defaultWarning: $default-warning;
	defaultDanger: $default-danger;
	inversePrimary: $inverse-primary;
	inverseSuccess: $inverse-success;	   
	inverseInfo: $inverse-info;        
	inverseWarning: $inverse-warning;   
	inverseDanger: $inverse-danger;  
	borderColor: $border-color;
	buttonBorderColor: $button-border-color;
	borderAccentColor: $border-accent-color;
	backgroundColor: $background-color;
	menuHeaderColor: $menu-header-color;
	focusColor: $focus-color;
	headerHeight: $header-height;
	offscreenSize: $offscreen-size;
	collapsedSize: $collapsed-size;
	collapsedSizeInner: $collapsed-size-inner;
	sideNavDark: $side-nav-dark;
	sideNavDarkFont: $side-nav-dark-font;
	sideNavDarkBorder: $side-nav-dark-border;
	logoColor: $logo-color;
	themePrimary: $theme-primary;
	themeInfo: $theme-info;
	themeSuccess: $theme-success;
	themeDanger: $theme-danger;
	themeDark: $theme-dark;
	facebook: $facebook;
	twitter: $twitter;
	instagram: $instagram;
	googlePlus: $google-plus;
	pinterest: $pinterest;
	skype: $skype;
	linkedin: $linkedin;
	youtube: $youtube;
	yahoo: $yahoo;
	rss: $rss;
	dropbox: $dropbox;
	soundCloud: $sound-cloud;
	vimeo: $vimeo;
	bing: $bing;
	dribbble: $dribbble;
	html5: $html5;
	sharethis: $sharethis;
	tumblr: $tumblr;
	wordpress: $wordpress;
}
