.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: $default-white;
    border: 1px solid $focus-color;
    border-radius: 0;
}
.well-sm {
    padding: 9px 15px;
    border-radius: 0;
    margin-bottom: -1px;
    h3 {
    	margin-bottom: 0;
    }
}
.well-lg {
    padding: 24px;
    border-radius: 6px;
}