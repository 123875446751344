@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ja6kwo');
  src:  url('../fonts/icomoon.eot?ja6kwo#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ja6kwo') format('truetype'),
    url('../fonts/icomoon.woff?ja6kwo') format('woff'),
    url('../fonts/icomoon.svg?ja6kwo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bitcoin-atm:before {
  content: "\e901";
}
.icon-buy-currency:before {
  content: "\e90b";
}
.icon-currency-purchase-with-card:before {
  content: "\e911";
}
.icon-sell-currency:before {
  content: "\e91d";
}