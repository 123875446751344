.help {
	position: relative;
    top: 1px;
    left: 5px;
    color: $default-logo;
    font-size: 13px;
}
.btn-primary .help {
	color: $default-white;
}
.tooltip.show {
	opacity: 1;
}
.tooltip-inner {
    background-color: $default-logo;
}
.settingsTooltip {
	.tooltip-inner {
		max-width: 450px;
	}
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before {
	border-top-color: $default-logo;
}
.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before {
	border-right-color: $default-logo;
}
.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
	border-bottom-color: $default-logo;
}
.tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before {
	border-left-color: $default-logo;
}