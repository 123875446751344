@charset "UTF-8";

@font-face {
  font-family: "exclusive-icon";
  src: url("../fonts/exclusive-icon.eot");
  src: url("../fonts/exclusive-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/exclusive-icon.woff") format("woff"), url("../fonts/exclusive-icon.ttf") format("truetype"), url("../fonts/exclusive-icon.svg#exclusive-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}


.ei {
  display: inline-block;
  font: normal normal normal 14px/1 exclusive-icon;
  font-size: inherit;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  font-family: "exclusive-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ei-"]:before, [class*=" ei-"]:before {
  font-family: "exclusive-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ei-ticket:before {
  content: "\e0bd";
}

.ei-money:before {
  content: "\e063";
}

.ei-conference:before {
  content: "\e04e";
}

.ei-deal:before {
  content: "\e04f";
}

.ei-pencil:before {
  content: "\e06c";
}

.ei-dollar:before {
    content: "\e38e";
}

.ei-search-alt:before {
    content: "\e268";
}

.ei-garbage-alt:before {
    content: "\e24c";
}

.ei-key:before {
    content: "\e05e";
}

.ei-credit-card-4:before {
    content: "\e08e";
}

.ei-bills:before {
    content: "\e043";
}

.ei-calendar-flip:before {
    content: "\e1d1";
}

.ei-refresh-2:before {
    content: "\e264";
}

.ei-preview:before {
    content: "\e151";
}

.ei-devaluation:before {
    content: "\e052";
}

.ei-lockers:before {
    content: "\e004";
}

.ei-money-bag:before {
    content: "\e065";
}

.ei-book:before {
    content: "\e044";
}

.ei-panel-1:before {
    content: "\e069";
}

.ei-safebox:before {
    content: "\e077";
}

.ei-power-button-alt:before {
    content: "\e261";
}

.ei-growth:before {
    content: "\e057";
}

.ei-euro:before {
    content: "\e39b";
}

.ei-schedules:before {
    content: "\e00b";
}

.ei-calendar-mark:before {
    content: "\e1d4";
}
 .ei-unchecked:before {
    content: "\";
 }
