.Select-control {
	border-radius: 0!important;
	border-color: $border-color!important;
	color: $default-dark!important;
	height: 37px!important;
	padding-left: 10px;
	padding-right: 10px;
	&:hover {
		box-shadow: none!important;
	    -webkit-box-shadow: none!important;
	}
	.Select-input {
		padding-left:0;
	}
}
.Select-placeholder, .Select--single > .Select-control .Select-value {
	line-height: 36px!important;
}
.Select-arrow {
	border: none!important;
	font-size: 12px;
	&:before {
		content: "\e64b";
		font-family: 'themify';
	}
}
.is-focused:not(.is-open) > .Select-control{
	box-shadow: none!important;
	-webkit-box-shadow: none!important;
}
.is-open > .Select-control .Select-arrow {
	top: 1px;
	&:before {
		content: "\e648";
	}
}
.Select-menu-outer {
	border-color: $border-color!important;
}
.Select input[type='hidden'] {
	cursor: none;
}

