button,
input,
textarea {
	outline: none;
}

.form-group {
	margin-bottom: 25px;
	label {
		margin-bottom: 5px;
	}
	&.percents {
		position: relative;
		.form-control {
			padding-right: 38px;
		}
		.form-error {
			background-color: white;
    		z-index: 9;
		}
	}
	.percents-icon {
		position: absolute;
		top: 26px;
	    right: 0;
	    font-size: 20px;
	    font-style: normal;
	    padding: 3px 12px;
	    border-left: 1px solid $border-color;
	}
}
.basic-form {
	.form-group {
		margin-bottom: 18px;
	}
} 
.input-with-select-group {
	input, .Select {
		float: left;
	}
	.Select {
		width: 70px;
		margin-left: -1px;
	}
	input {
		width: calc(100% - 70px);
	}
}
.form-control {
	border: 1px solid $border-color;
	border-radius: 0;
	box-shadow: none;
	height: 37px;
	font-size: 14px;
	padding: 0 10px;
	color: $default-dark;
	outline: 1px solid transparent;
	@include transition(all 0.2s ease-in); 
	@include placeholder {
		color: lighten($default-text-color, 30%);
	}

	&:focus {
		outline: 1px solid lighten($default-info, 10%);
		box-shadow: none;
		border-color: lighten($default-info, 10%);		
	}	

	&.input-sm {
		height: 30px;	
	}

	&.input-lg {
		height: 50px;	
	}

	&.error {
		border-color: $default-danger;
		background-color: $inverse-danger;
	}

	&.valid {
		border-color: $default-success;
		background-color: $inverse-success;
		color: darken($default-success, 15%);
	}
}
select.form-control {
	-webkit-appearance: none;
	padding-top: 6px;
    padding-bottom: 6px;
}
.delete {
	color: $default-dark;
	i {
		font-size: 16px;
		margin-left: 5px;
	}
}
.has-success {

	.form-control {
		box-shadow: none;
		border-color: $default-success;

		&:focus {
			border-color: $default-success;
			box-shadow: none;
		}
	}

	.checkbox,
	.checkbox-inline,
	.control-label,
	.help-block,
	.radio,
	.radio-inline,
	&.checkbox label
	&.checkbox-inline label,
	&.radio label,
	&.radio-inline label {
		color: $default-success;
	}
}

.has-warning {

	.form-control {
		box-shadow: none;
		border-color: $default-warning;

		&:focus {
			border-color: $default-warning;
			box-shadow: none;
		}
	}

	.checkbox,
	.checkbox-inline,
	.control-label,
	.help-block,
	.radio,
	.radio-inline,
	&.checkbox label
	&.checkbox-inline label,
	&.radio label,
	&.radio-inline label {
		color: $default-warning;
	}
}

.has-error {

	.form-control {
		box-shadow: none;
		border-color: $default-danger;

		&:focus {
			border-color: $default-danger;
			box-shadow: none;
		}
	}

	.checkbox,
	.checkbox-inline,
	.control-label,
	.help-block,
	.radio,
	.radio-inline,
	&.checkbox label
	&.checkbox-inline label,
	&.radio label,
	&.radio-inline label {
		color: $default-danger;
	}
}

textarea {
	&.form-control {
		min-height: 175px;				
	}
	&.textarea-small {
		min-height: 74px;
		padding: 10px;
	}	
}

.input-icon {
	position: relative;

	i {
		position: absolute;
		left: 10px;
   		top: 15px;
   		color: lighten($default-text-color, 30%);
	}

	.form-control {
		padding-left: 40px;
	}
	
}

.input-group-addon {
	background-color: transparent;
	border: 1px solid $border-color;
	color: lighten($default-text-color, 30%);
	
	.btn {
		margin: 0px;
		padding: 0px;
		background-color: transparent;
	}
}
.input-group-text {
	border-radius: 0;
    margin-left: -1px;
    background-color: $menu-header-color;
}

.timepicker-input {
	.input-group-addon {
		cursor: pointer;
	}
}
input.number[type='number'] {
    -moz-appearance:textfield;
}

input.number::-webkit-outer-spin-button,
input.number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.checkbox {
	padding: 7px 0;
    min-height: auto;
	
	input[type=checkbox] {
		margin: 0;
		display: none;
		width: 22px;
		
		+label {
			padding-left: 0;
			
			&:before {
				content: "";
				width: 22px;
				height: 22px;
				display: inline-block;
				border: 2px solid $border-color;
				border-radius: 3px;
				margin-right: 10px;
				font-size: 15px;
				font-family: 'themify';
				font-weight: 400;
				line-height: 19px;
				vertical-align: bottom;
				text-align: center;
				background-color: $default-white;
				cursor: pointer;
			}
		}
		
		&:checked {
			
			+label {
				&:before {
					content: "\e64c";
					color: $default-info;
				}
			}
		}
	}

	&.checkbox-primary {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						content: "\e64c";
						color: $default-primary;
					}
				}
			}
		}	
	}

	&.checkbox-success {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						content: "\e64c";
						color: $default-success;
					}
				}
			}
		}	
	}

	&.checkbox-warning {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						content: "\e64c";
						color: $default-warning;
					}
				}
			}
		}	
	}

	&.checkbox-danger {
		input[type=checkbox] {
			&:checked {
			
				+label {
					&:before {
						content: "\e64c";
						color: $default-danger;
					}
				}
			}
		}	
	}
}

.radio {
	padding: 7px 0;
    min-height: auto;
	
	input[type=radio] {
		margin: 0;
		display: none;
		width: 22px;
		
		+label {
			padding-left: 0;
			
			&:before {
				content: "";
				width: 22px;
				height: 22px;
				display: inline-block;
				border: 2px solid $border-color;
				border-radius: 50%;
				margin-right: 10px;
				font-size: 14px;
				font-family: 'FontAwesome';
				font-weight: 400;
				line-height: 19px;
				vertical-align: bottom;
				text-align: center;
				background-color: $default-white;
				cursor: pointer;
			}
		}
		
		&:checked {
			
			+label {
				&:before {
					content: "\f111";
					color: $default-info;
				}
			}
		}
	}

	&.radio-primary {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						content: "\f111";
						color: $default-primary;
					}
				}
			}
		}	
	}

	&.radio-success {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						content: "\f111";
						color: $default-success;
					}
				}
			}
		}	
	}

	&.radio-warning {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						content: "\f111";
						color: $default-warning;
					}
				}
			}
		}	
	}

	&.radio-danger {
		input[type=radio] {
			&:checked {
			
				+label {
					&:before {
						content: "\f111";
						color: $default-danger;
					}
				}
			}
		}	
	}
}

.radio,  
.checkbox {
	label{
		cursor: pointer;
	}
}	

.checkbox-inline,
.radio-inline {
	margin-right: 15px;
	margin-top: 0px;
	display: inline-block;
	.check-inline-label {
		float: left;
	    line-height: 33px;
	    margin-right: 10px;
	}
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	 margin-top: 0px; 
     margin-left: 0px;
}

.toggle-checkbox {

	input[type=checkbox] {
		opacity: 0;
		position: absolute;

		+label {
			position: relative;
		    display: inline-block;
		    @include transition(.4s ease);
		    height: 30px;
		    width: 50px;
		    border: 1px solid $border-color;
		    border-radius: 60px;
		    cursor: pointer;

		    &:before {
		    	content: "";
				position: absolute;
		        display: block;
				@include transition(0.2s cubic-bezier(0.24, 0, 0.5, 1));
				height: 30px;
				width: 50px;
				top: 0;
				left: 0;
				border-radius: 30px;
				background-color: $default-text-color;
		    }

		    &:after {
		    	content: "";
				position: absolute;
				display: block;
				box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
				@include transition(0.35s cubic-bezier(0.54, 1.6, 0.5, 1));
				background: darken($default-white,3%);
				height: 28px;
				width: 28px;
				top: 0px;
				left: 0px;
				border-radius: 60px;
		    }
		}

		&:checked {

			+label {

				&:before {
					background: $default-info;
					@include transition(width 0.2s cubic-bezier(0, 0, 0, 0.1));
				}

				&:after {
					left: 24px;
				}
			}
		}
	}


	&.toggle-sm {
		input[type=checkbox] {

			+label {
				height: 20px;
				width: 35px;

				&:before {
					height: 20px;
					width: 35px;
				}

				&:after {
					height: 19px;
					width: 19px;
				}
			}

			&:checked {

				+label {
					&:after {
						left: 16px;
					}
				}
			}	
		}
	}

	&.toggle-primary {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-primary;
  					}
  				}
  			}
		}	
	}

	&.toggle-success {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-success;
  					}
  				}
  			}
		}	
	}

	&.toggle-warning {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-warning;
  					}
  				}
  			}
		}	
	}

	&.toggle-danger {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-danger;
  					}
  				}
  			}
		}	
	}
}


