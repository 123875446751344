.auth0-lock-container .auth0-lock.auth0-lock{
	.auth0-lock-overlay {
	    background: -webkit-radial-gradient(#757474, #000000) rgb(72, 72, 72);
	    background: radial-gradient(#757474, #000000) rgb(72, 72, 72);
	}
	.auth0-lock-widget {
		border-radius: 0; 
	}
	.auth0-lock-cred-pane {
		border-radius: 0;
	}
	.auth0-lock-submit {
		border-radius: 0;
		background-color: #4d4d4d!important;
    	font-weight: 700;
	}
	.auth0-lock-header {
		height: 100px;
	}
	.auth0-lock-name {
		display: none;
	}
	.auth0-lock-header-logo {
		margin-bottom: 0;
		margin-top: 10px;
	}
	.auth0-lock-input-wrap {
		border-radius: 0;
		.auth0-lock-input {
			border-radius: 0;
		}
	}
}
