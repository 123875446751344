
.table>tbody>tr>td,
.table>tbody>tr>th, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th {
	line-height: 1.8;
	border-color: $border-color;
}
.table-lg>tbody>tr>td,
.table-lg>tbody>tr>th, 
.table-lg>tfoot>tr>td, 
.table-lg>tfoot>tr>th, 
.table-lg>thead>tr>td, 
.table-lg>thead>tr>th {
	padding: 15px 10px;
}
.table > tbody > tr > td {
	&.hiddenRow {
		padding: 0;
		border: 0;
	}
}

.table-xl>tbody>tr>td,
.table-xl>tbody>tr>th, 
.table-xl>tfoot>tr>td, 
.table-xl>tfoot>tr>th, 
.table-xl>thead>tr>td, 
.table-xl>thead>tr>th {
	padding: 20px;
}

.table-striped{
	> tbody {

		> tr {

			&:nth-of-type(odd) {
				background-color: lighten($default-gray, 1.5%);
			}
		}
	}
}

.table-light {
	th {
		border-bottom: 1px solid $border-color!important; 	
	}
	th, td {
		font-weight: 400;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}
.table-hover  {
	> tbody{

		> tr {

			&:hover {
				background-color: $default-gray;
			}
			&.no-hover:hover {
				background-color: $default-white;
			}
		}
	}
}
.table-bordered {
    border: 1px solid $border-color;
}
.table-no-border {
    border: none;
    th, td {
    	border: none;
    }
}

.table {	
	margin-bottom: 0;
	> thead {
		>tr {
			> th {
				color: $default-dark;
				background-color: $default-gray;
				border-bottom: 1px solid $focus-color;
				border-top: 0;
				font-weight: normal;
				padding: 15px 10px; 
				.sort {
					float: right;
					color: $focus-color;
					font-size: 17px;
					position: relative;
					i {
						position: absolute;
						cursor: pointer;
						&.fa-caret-up {
							top: -3px;
						}
						&.fa-caret-down {
							top: 6px;
						}
					}
					.active {
						color: $default-dark;
					}
				}
				&.th-small {
					padding-left: 0!important;
					padding-right: 0!important;
				}
			}

			> td {
				
			}
			&.light {
				> th {
					background-color: transparent;
				}
			}
		}
	}
	&.table-lg {
		> thead {
			>tr {
				> th {
					padding: 15px 10px;
				}
			}
		}
	}
	> tbody {
		>tr {
			&.active {
				td {
					background-color: $default-gray;
				}
				&:not(.trHidden), &:not(.trHiddenRow) {
					border-bottom:1px solid $focus-color;
				}
			}
			>td {
				h5 {
					margin: 0;
				}
				&.td-small {
					padding-left: 0;
					padding-right: 0;
				}
			}
			&.trHidden, &.trHiddenRow {
				.border-right {
					border-right: 1px solid $focus-color;
				}
				p {
					color: $default-dark;
					letter-spacing: -0.3px;
					font-size: 13px;
				}
			}
			&.searchRow {
				background-color:  $default-white;
				td {
					padding: 10px;
				}
			}
		}
	}
}
.table-sm>thead>tr>th,
.table-sm>tbody>tr>td,
.table-sm>tbody>tr>th, 
.table-sm>tfoot>tr>td, 
.table-sm>tfoot>tr>th, 
.table-sm>thead>tr>td, 
.table-sm>thead>tr>th {
	padding: 5px;
}
.table-bordered-top {
	> thead {
		>tr {
			> th {
				border-top: 1px solid $focus-color;
			}
		}
	}
}
.table-inner {
	&.table > tbody > tr td {
		background-color: $default-white;
	}
	&.table-bordered-top > thead > tr > th {
	    border-top: 1px solid $border-accent-color;
	}
}
.table-overflow {
	@include screen-tablet {
		width: 100%;
		overflow-x: auto;
	}
}
.profit-td {
	&.empty,
	.text-success {
		padding-left: 7px;
	}
}
.v-align-top {
	vertical-align: top;
}
.stats-table {
	margin-top: 0;
	tr {
		border-bottom: 1px solid $focus-color;
		&:last-child {
			border-bottom: none;
		}
	}
	td {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
