.modal {
	&.fade {
		.modal-dialog {
			@include transition(all 0.2s ease-out); 
			 @include transform(translateX(0%) translateY(0%));
		}
		
		&.slide-in-right {
			
			.modal-dialog {
				@include transform(translateX(100%));	
			}
			
			&.show {
				.modal-dialog {
					@include transform(translateX(0%));
				}
				
			}
		}
		
		&.slide-in-left {
			
			.modal-dialog {
				@include transform(translateX(-100%));	
			}
			
			&.show {
				.modal-dialog {
					@include transform(translateX(0%));
				}
				
			}
		}
		
		&.modal-fs {
			.modal-dialog {
				@include transform(translateY(0%));
			}
			
			&.show {
				.modal-dialog {
					@include transform(translateY(0%));
				}
				
			}
		}
	}
	.modal-md-s {
		max-width: 600px; 
	}
	.modal-md {
		max-width: 800px;
	}
	.modal-lg {
		max-width: 990px;
	}
	.modal-xl {
		max-width: 1350px;
	}
}

.modal-header {
	padding: 10px 0;
    border-bottom: 1px solid $border-color;
    margin-left: 18px;
    margin-right: 18px;
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 0px;
	}
	.close {
		margin: -12px -15px -17px auto;
		font-size: 20px;
	}
}

.modal-body {
	padding: 20px;
	.btn {
		margin-bottom: 0;
	}
}

.modal-footer {
	padding: 15px 20px;
	border-top: 1px solid $border-color;
	text-align: left;
	
	.btn {
		margin-bottom: 0px;
		
		+.btn {
			margin-left: 0px;
		}
	}
}

.modal-content {
    position: relative;
    background-color: $default-white;
    border: 0px;
    border-radius: 5px;
    outline: 0;
}

.modal-right,
.modal-left {

	.modal-dialog {
		width: 400px;
		height: 100%;
		margin: 0px auto 0px 0px ;
		
		@include screen-mobile {
			width: 260px;
		} 
		
		.modal-content {
			height: 100%;
			border-radius: 0px;
			
			.side-modal-wrapper {
				height: 100%;
				padding-left: 15px;
				padding-right: 15px;
				position: relative;
			}
			
			.modal-footer {
				position: absolute;
				bottom: 0px;
				width: calc(100% - 30px);
				left: 15px;
			}
		}
	}
}

.modal-close {
	
}

.modal-big {
	max-width: 600px;
}

.modal-right {
	.modal-dialog {
		margin: 0px 0px 0px auto;
	}
}

.modal-backdrop {
	z-index: 1040;
	background-color: $default-dark;
}

@include screen-mobile-above {
	.modal-sm {
		width: 350px;
	}
} 

@include screen-laptop {
	.modal .modal-xl { 
		max-width: 100%;
		margin: 10px;
	}
}

.modal-fs {
	.modal-dialog {
		width: 100%;
		margin: 0px auto;
		height: 100%;
		max-width: none;
		
		.modal-content {
			height: 100%;
			border-radius: 0px;
			@include background-opacity($default-white, 0.9); 
		}
	}
	
	.modal-close {
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 7px 10px;
		border: 1px solid lighten($default-dark, 30%);
		border-radius: 50px;
		color: lighten($default-dark, 30%);
		
		&:hover,
		&:focus {
			color: $default-dark;
			text-decoration: none;
			border: 1px solid $default-dark;
		} 
	}
}
.modal-lg {
	width: 1020px;
    max-width: none;
}

