.simple-data-box {
	height: 90%;
	.text-div {
		width: 67%;
		@include screen-laptop {
			width: 70%;
		}
	}
	.icon-div {
		text-align: right;
    	width: 33%;
    	margin-top: 15px;
    	@include screen-laptop {
    		width: 30%;
    	}
	}
	.simple-data-inner {
		display: flex;
		padding-left: 23px;
    	padding-right: 23px;
		@include screen-laptop {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	p {
		text-transform: uppercase;
		font-size: 12px;
	}
	i {
		font-size: 40px;
    	top: 10px;
		position: relative;
    	right: 7px;
    	&.xl {
			font-size: 55px;
		    position: absolute;
		    right: 25px;
		    top: 25px;
		}
		@include screen-laptop {
			font-size: 40px;
			top: 8px;
			&.xl {
				font-size: 45px;
			}
		}
	}
	.icon-medium i {
		font-size: 35px!important;
    	position: relative;
    	top: 4px;
	}
	.icon-big i {
		font-size: 41px!important;
    	position: relative;
    	top: 0;
	}
	h2 {
		margin-bottom: 0;
		letter-spacing: -0.5px;
		line-height: 36px;
		font-size: 21px;
		span {
			font-size: 16px;
    		margin-left: 5px;
		}
	}
	.inline-header {
		h2, p {
			float: left;
			width: 48%;
			width: calc(50% - 3px);
		}
		h2:first-child, p:first-child {
			margin-right: 5px;
			padding-right: 5px;
			border-right: 1px solid $border-color;
		}
	}
	&.simple-data-small {
		h2 {
			font-size: 19px;
		}
		i {
			font-size: 30px;
		}
		.simple-data-inner {
			padding-right: 15px;
			padding-left: 15px;
		}
		.text-div {
			width: 80%
		}
		.icon-div {
			width: 20%;
		}
	}
	.simple-box-double {
		.text-div {
			width: 90%
		}
		.icon-div {
			width: 10%;
		}
	}
}
.accent-data-box {
	h1 span {
		font-size: 20px;
		margin-left: 5px;
	}
}