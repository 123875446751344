.swal2-modal {
	font-family: $font-primary!important;
	.swal2-title {
		color: $default-dark!important;
		font-size: 28px!important;
		font-weight: normal!important;
		margin-bottom: 15px!important;
	}
	.swal2-content {
		font-size: 14px!important;
		color: $default-text-color!important;
		margin-bottom: 20px!important;
	}
	.swal2-styled:focus {
		box-shadow: none!important;
		-webkit-box-shadow: none!important;
	}
}
.swal2-cancel, .swal2-confirm {
	font-size: 13px!important;
	padding: 9px 26px!important;
    line-height: 20px!important;
    border-radius: 0!important;
}
.swal2-confirm.btn-long {
	padding-left: 45px!important;
	padding-right: 45px!important;
}
.swal2-confirm.long {
	padding-left: 145px!important;
    padding-right: 145px!important;
}
.swal2-cancel {
	color: $default-text-color!important;
	border: 1px solid $border-color!important;
	margin-right: 30px!important;

}
.swal2-buttonswrapper {
	margin-bottom: 15px;
	@include clearfix();
}
.swal2-container b {
	color: $default-dark;
}
.swal2-container.swal2-shown {
	background-color: rgba(26, 59, 89, 0.5)!important;
}
#alertDiv {
	//display: none;
	position: fixed;
	min-width: 35%;
	z-index: 999;
    right: 20px;
    top: 20px;
}
.alert {
	font-size: 16px;
	padding-top: 11px;
    padding-bottom: 11px;
	.fa {
		font-size: 24px;
	    opacity: 0.4;
	    padding-right: 10px;
	    position: relative;
	    top: 3px;
	}
}
.alert-info {
	background-color: $inverse-info;
	border-color: $inverse-info;
}