.authentication {

    .sign-in {
        .img-caption {
            position: absolute;
            bottom: 0px;
            left: 0px;
            padding: 30px;
            max-width: 600px;

            .caption-title {
                color: $default-white;
                font-size: 35px;
                font-weight: 300;
            }

            .caption-text {
                color: darken($default-white, 10%);
            }
        }

        .login-footer {
            position: absolute;
            bottom: 0px;
            padding: 15px 30px;
            border-top: 1px solid $border-color;
            width: 100%;
        }
    }
}