.pagination-content {
	border-top: 1px solid $border-color;
	padding-top: 20px;
	display: flex;
	.pages-summary {
		margin-right: 10px;
		margin-bottom: 0;
		min-width: 149px;
		margin-top: 12px;
	}
	.pages-number {
		margin-bottom: 0;
		margin-left: 11px;
		display: flex;
		position: relative;
		.Select {
			width: 75px;
			margin-left: 15px;
			height: 38px;
			float: left;
			margin-top: 7px;
		}
		.form-error {
			float: none;
    		margin-top: 0;
    		right: -3px;
    		top: 33px;
		}
		p {
			margin-right: 10px;
			margin-bottom: 0;
			margin-top: 12px;
		}
	}
	.nav {
		width: 50%;
		width: calc(100% - 435px);
		float: left;
		nav {
			margin: auto;
		}
	}
}
.page-link {
	border-color: transparent;
	border-radius: 2px;
	font-size: 18px;
	padding: 6px 12px;
	max-height: 36px;
	min-width: 40px;
	color: $default-dark;
	cursor: pointer;
	&:focus, &:hover {
		border-color: transparent;
		color: $default-dark;
	}
	@include screen-laptop {
		padding-left: 4px;
		padding-right: 4px;
		font-size: 16px;
		min-width: 39px;
	}
}
.page-item {
	margin-right: 7px;
	@include screen-laptop {
		margin-right: 0;
	}
	&:first-child, &:last-child {
		.page-link {
			border-radius: 2px;
		}
	}
	&.next, &.prev {
		.page-link {
			font-size: 12px;
			padding: 10px 12px;
		}
	}
	&.active {
		.page-link {
			border-color: transparent;
			background-color: $default-dark;
		}
	}
	&.disabled {
		.page-link {
			border-color: transparent;
		}
	}
	&.break {
		padding: 6px 14px;
	}
}
#paginationCount {
	width: 80px;
	margin-top: 5px;
}