.export {
	margin-top: 30px;
	margin-bottom: 15px;
	.btn {
		margin-right: 0;
	}
	.btn-primary {
		&:focus,
		&:active,
		&:hover {
			color: $default-white!important;
		}

	}
	.dropdown-menu {
		a {
			display: block;
			padding: 5px 10px;
			transition: all 0.3s;
			&:hover {
				background-color: $default-info;
				color: $default-white;
			}
		}
	}
	&.show {
		.btn {
			color: $default-dark;
			background-color: lighten($border-color, 2%);
			border-color: lighten($border-color, 2%);
	    	i {
				&:before {
					content: "\e648";
				}
			} 
		}
	}
}
table .export, .modal .export {
	margin-top: 0;
	margin-bottom: 0;
	.btn {
		float: none;
	}
}
.modal .export .dropdown-menu {
	width: 95%;
	width: calc(100% - 30px);
}