.page-container {
	min-height: 100vh;
	padding-left: $offscreen-size;
	@include transition(all 0.2s ease); 
	@include print {
		padding-left: 0px;
	}

	@include screen-laptop-only {
		padding-left: $collapsed-size;
	}

	@include screen-tablet {
		padding-left: 0px;;
	} 

	.main-content {
		padding: 80px 5px 15px 5px;
		min-height: calc(100vh - 60px);

		@include print {
			padding: 10px 0px;
		}

		@include screen-tablet {
			padding: 85px 5px 5px 5px;
		}	
	}
}

.full-container {
	position: absolute;
    top: $header-height;
    left: $offscreen-size;
    right: 0px;
    min-height: calc(100vh - #{$header-height});
    @include transition(all 0.2s ease); 

    @include screen-laptop-only {
		padding-left: $collapsed-size;
		left: 0px;
	}

    @include screen-tablet {
    	left: 0px;
    }
}

.is-collapsed {

	.page-container {
		padding-left: $collapsed-size;

		@include screen-tablet {
			padding-left: 0px;	
		}	

		@include screen-laptop-only {
			padding-left: $offscreen-size;
		}	
	}

	.full-container {
		left: $collapsed-size;

		@include screen-tablet {
			left: 0px;	
		}

		@include screen-laptop-only {
			left: $offscreen-size;
			padding-left: 0px;
		}	
	}
} 
